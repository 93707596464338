:root {
  --dark: black;
  --light: white;
  --blue: #13213f;
  --purple: #30253c;
  --transparent: rgba(255, 255, 255, 0);
}

a {
  font-size: 20px;
}

#about {
  width: 100vw;
  padding: 60px;
}

.aboutBox {
  margin-top: 10px;
}

.active {
  font-weight: bolder;
}

.App {
  text-align: center;
}

.appContainer {
  position: relative;
}

button {
  border: none;
  outline: none;
}

.buttonText {
  font-weight: 500;
}

.buttonText:hover,
button:hover {
  text-decoration: underline;
}

.card {
  background-color: var(--transparent);
  padding: 0;
  margin: 0;
}

.card-header,
.card-footer {
  border: 0;
  background-color: var(--transparent);
}

.card-title {
  text-align: center;
}

#canvasContainer {
  width: 100%;
  margin: auto;

  left: 0;
  height: 400px;
  top: 0;
}

#canvasContainer:hover {
  border: 1px solid red;
}

.content {
  margin-top: 40px;
}

.errMessage {
  color: red;
  font-size: 20px;
}

form {
  border-radius: 5px;
  height: 100%;
  padding: 10px;
  margin: auto;
}

.form-btn-primary {
  /*outline: 1px solid var(--transparent);*/
  /*border: none;*/
  color: var(--dark);
  background-color: var(--transparent);
  width: 100%;
}

.form-btn-primary:hover {
  /*border-top: 1px solid var(--transparent);*/
  background-color: var(--transparent);
  color: var(--dark);
}

.form-btn-primary:focus {
  box-shadow: none;
  outline: none;
}

.form-btn-primary:disabled {
  cursor: not-allowed;
  border: 0;
  pointer-events: none;
  background-color: var(--transparent);
  /*border: 1px solid var(--light);*/
  color: var(--light);
}

.formContainer {
  position: relative;
  margin-top: 10px;
  width: 100%;
  z-index: 2;
}

footer {
  position: relative;
  text-align: center;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.footerIcon {
  font-size: 40px;
  padding: 10px;
}

html {
  scroll-behavior: smooth;
}

h1 {
  text-align: center;
}

html,
body {
  background-color: var(--blue);
  height: 100%;
  width: 100%;
  margin: 0%;
  overflow-x: hidden;
  background-blend-mode: color;
  /*background-image: url("./images/abstract_image.png");*/
}

.htmlEditForm {
  width: 100%;
  height: 400px;
}

.htmlEditForm input,
.modelForm input {
  float: right;
}

.htmlEditForm input[type="number"] {
  width: 25%;
}

label {
  font-size: 15px;
}

.lightForm {
  position: relative;
  top: 5px;
  width: 100%;
  z-index: 2;
}

.icons {
  padding-left: 10px;
  width: 70px;
  height: 70px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border: none;
  padding: 0%;
}

p {
  font-size: 20px;
  margin: auto;
}

.projectImage {
  object-fit: contain;
}

.profilePic {
  clip-path: circle(48%);
  width: 200px;
  height: 200px;
  object-fit: contain;
  background-color: var(--light);
  margin-left: 20px;
  z-index: 1;
}

@media screen and (max-width: 990px) {
  .profilePic {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  .profilePic {
    width: 200px;
    height: 200px;
    float: right;
  }
}

#projects {
  margin: 0;
  padding: 0;
}

.modelForm,
#navbar-nav {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255, 252, 252, 0.5);
  padding: 10px;
}

.modelFormButton {
  position: relative;
  bottom: 60px;
  margin: auto;
  width: 100%;
  z-index: 1;
}

.modelForm {
  top: -40px;
  left: 0%;
  padding-bottom: 10px;
  position: relative;
  height: 100%;
}

.modelForm p {
  font-size: 15px;
}

#navbar-nav {
  height: fit-content;
}

.navIcon {
  font-size: 40px;
}

.nav-link {
  text-decoration: none;
  color: var(--dark);
  font-size: 20px;
  border: none;
}

/* React uses link:hover as a current page link instead of normal hover behaviour, style this to show current page link*/
.nav-link:hover {
  text-decoration: underline;
  border: none;
}

.noButton {
  width: 200px;
  text-align: left;
  margin-right: 20px;
  text-decoration: none;
  border: none;
  background-color: var(--transparent);
  position: relative;
}

.noButton:focus {
  border: none;
}

.noEffect,
.noEffect:hover {
  position: absolute;
  top: 0px;
  left: 0px;
  text-decoration: none;
  border: none;
  background-color: var(--transparent);
}

.resumeContainer {
  width: 100%;
  height: 100%;
}

select {
  width: 100%;
  height: 40px;
}

.servicesIcon {
  font-size: 40px;
}

.sliderPos {
  width: 100%;
  height: 10px;
}

.subtitle {
  font-size: small;
  font-weight: 800;
  font-variant: small-caps;
}

table {
  width: 100%;
}

.title {
  font-size: 100px;
  font-family: Tahoma;
}

@media screen and (max-width: 990px) {
  .title {
    font-size: 80px;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-size: 60px;
  }
}

tr {
  width: auto;
}

td {
  width: auto;
  font-size: 20px;
}

.wireframe {
  margin-left: 10px;
}
